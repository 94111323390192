<template>
<nav role="navigation" class="">
  <div id="menuToggle">
    <!--
    A fake / hidden checkbox is used as click reciever,
    so you can use the :checked selector on it.
    -->
    <input type="checkbox" ref='checkbox'/>

    <!--
    hamburger menu
    -->
    <span></span>
    <span></span>
    <span></span>

    <ul id="menu">
      <router-link to="/"><li @click='close()'>Home</li></router-link>
      <router-link to='/#portfolio'><li @click='close()'>Portfolio</li></router-link>
      <router-link to="/#about"><li @click='close()'>About</li></router-link>
      <router-link to="/#skills"><li @click='close()'>Skills</li></router-link>
      <router-link to="/#contact"><li @click='close()'>Contact</li></router-link>

    </ul>
  </div>
</nav>
</template>

<script>
    export default {
        name: "Navigation",
        components: {
        },
        methods:{
            close(){
                let b=this.$refs.checkbox;
                b.checked=false;

            }
        }
    }
</script>

<style scoped >
#menuToggle
{
  display: block;
  position: relative;
  top: 60px;
  left: 50px;
  z-index: 1;
  height: 102vh;
  -webkit-user-select: none;
  user-select: none;
}
#menuToggle a
{
  text-decoration: none;
  color: var(--primary-color);
  transition: color 0.3s ease;
}
#menuToggle a:hover
{
  color: var(--bg-color);
}
#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  -webkit-touch-callout: none;
}
/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: var(--primary-color);
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}
#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}
#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}
/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: var(--dark-color);
}
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}
#menu
{
  position: absolute;
  width: 200px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  height: 105vh;
  background: var(--accent-color);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}
#menu li
{
  padding: 10px 0;
  font-size: 22px;
}
#menuToggle input:checked ~ ul
{
  transform: none;
}
</style>